import React, {useState, memo} from 'react'
import { MdWifiCalling } from "react-icons/md"
import {motion} from "framer-motion"
import {fadeIn} from "./variants"
import Navigation from './Navigation'
import {TiThMenu} from "react-icons/ti"

const Header = () => {
  const [Menu, setMenu] = useState(false)
  return (
    <div className='fixed bg-white/100 text-primary/100 z-50 w-full mx-auto px-[50px] py-4 shadow-md flex justify-between items-center ' >
        <h2
            className=' text-primary font-bold' >
                Bienestar wellness
        </h2>
        <div className='hidden lg:block' >
        <Navigation Menu={setMenu}/>
        </div>
        
        <div className='lg:hidden' onClick={()=>{
          setMenu(!Menu)
        }} >
          <TiThMenu/>
        </div>
        {
          Menu&&
          <div className='lg:hidden fixed left-0 w-[60%] text-tertiary bg-white/100 h-[100%] z-40 top-[50px] pl-7 ' >
            <Navigation Menu={setMenu} />
          </div>
        }
    </div>
  )
}

export default memo(Header)
