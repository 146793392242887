import React, {memo} from 'react'
import logo from './logo.svg';
import './App.css';
import Header from './Components/Header';
import Banner from './Components/Banner';
import Vision from './Components/Vision';
import Values from './Components/Values';
import Contact from './Components/Contact';
import Services from './Components/Services';
import {Helmet} from "react-helmet"

function App() {
  return (
    <div className='font-thin bg-site px-0 bg-no-repeat bg-fixed bg-cover overflow-hidden'>
      <Header/>
      <Banner/>
      <Services/>
      <Values/>
      <Vision/>
      <Contact/>
      {/* <div className='h-[2000px]' ></div> */}
    </div>
  );
}

export default memo(App);
