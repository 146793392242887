import React, { useState,memo } from 'react'
import {motion} from "framer-motion"
import { fadeIn } from "./variants"
import { GiFlyingFlag } from "react-icons/gi"
import { SiWalkman } from "react-icons/si"
import {services} from "./data"
import {Link} from "react-scroll"
import {AiFillCloseSquare} from "react-icons/ai"
import {LazyLoadImage} from "react-lazy-load-image-component"

const Services = () => {
  const [Clicked, setClicked] = useState();
  const [selectedImage, setSelectedImage] = useState(0)
  return (
    <div id="services" className='w-[100%] py-[100px]' >
      
      <h2 className='text-center font-bold uppercase text-3xl text-tertiary mt-5 mb-5 ' >What we do</h2>
        <div className='grid grid-flow-row grid-cols-1 gap-x-0 lg:gap-x-3 gap-y-3 md:grid-cols-2 md:gap-x-3 lg:grid-cols-3 backdrop-blur-sm lg:flex-row w-[85%] mx-auto text-center text-primary p-1 lg:p-5 ' >
            {
              services.map((service,i)=>{
                return(
                  <div  key={i} className=' cursor-pointer text-justify gap-y-2 bg-white/100 flex flex-col shadow-sm p-5 rounded-sm hover:cursor-pointer' >
                    
                    <h2 className='font-bold' >{service.title}</h2>
                    <p>{
                        service.desciption.length>180
                        ?
                        service.desciption.substring(0,180)+' ...'
                        :
                        service.desciption
                      }</p>
                      <div className='flex gap-x-2 items-center' >
                        {
                          service.images.map((iage, i)=>(
                            <div key={i} className='bg-slate-400 w-[40px] h-[40px] rounded-sm' >
                              <LazyLoadImage alt='' src={iage.src} className="w-full h-full object-cover rounded-sm " />
                            </div>
                          ))
                        }
                        <Link onClick={()=>setClicked(i)} className='p-2 text-blue-700 ' >See More</Link>
                      </div>
                  </div>
                )
              })
            }
        </div>
        {
          Clicked&&
          <div className='fixed backdrop-blur-xl z-50 bg-white/10 top-0 w-full h-full ' >
            <div className=' overflow-x-hidden w-[90%] h-[80%] rounded-sm p-4 bg-white mx-auto my-[7%] flex flex-col md:flex-row gap-y-2 gap-x-0 md:gap-x-2 md:gap-y-0' >
            <div className='hidden md:h-full md:flex flex-1 bg-red-300' >
              <LazyLoadImage src={services[Clicked].images[selectedImage].src} alt='' className='w-full h-full object-cover ' />
              
              <div className='sm:flex md:hidden flex-col' >
                  <h2 className='mt-4 font-bold ' >Gallery</h2>
                  <div className='flex mt-4 gap-x-2 overflow-x-scroll ' >
                    {
                      services[Clicked].images.map((image,i)=>(
                        <LazyLoadImage alt='' key={i} onClick={()=>setSelectedImage(i)} src={image.src} className="w-[80px] h-[80px] object-cover rounded-sm shadow-md " />
                      ))
                    }
                  </div>
              </div>
            </div>
            <div className='flex flex-1 flex-col px-2 text-justify sm:pb-4 ' >
              <h2 className='font-bold py-4 justify-between items-center flex ' >
                <span>{services[Clicked].title}</span>
                <AiFillCloseSquare className='text-3xl text-red-600 cursor-pointer ' onClick={()=>{
                  setClicked(null);
                  setSelectedImage(0)
                }} />
              </h2>
              <div className='w-10 h-1 bg-primary' ></div>
              <p className='pr-2 mt-2 sm:pb-4' >{services[Clicked].desciption}</p>

              <div className='md:hidden' >
                <div className='flex-1 sm:h-[300px] ' >
                  <LazyLoadImage src={services[Clicked].images[selectedImage].src} alt='' className='w-full h-full object-cover ' />
                </div>
              </div>

              <div className='md:flex md:flex-col sm:flex sm:flex-col' >
                <h2 className='mt-4 font-bold ' >Gallery</h2>
                <div className='flex flex-row mt-4 pb-4 gap-x-2 overflow-x-scroll -scroll-m-1 ' >
                  {
                    services[Clicked].images.map((image,i)=>(
                      <LazyLoadImage key={i} alt='' src={image.src} onClick={()=>setSelectedImage(i)} className="w-[80px] h-[80px] object-cover rounded-sm shadow-md " />
                    ))
                  }
                </div>
              </div>
            </div>
            </div>
          </div>
        }
    </div>
  )
}

export default memo(Services)
