import React,{memo} from 'react'
import {TypeAnimation} from "react-type-animation"
import BPimage from "../assets/bp.png"
import {motion} from "framer-motion"
import {fadeIn} from "./variants"

const Banner = () => {
  return (
    <div id="home" className=' flex-col lg:flex-row flex items-center justify-between w-[90%] lg:w-[85%] mx-auto py-5 pt-28 ' >
      <motion.div
        variants={fadeIn('right',.3)}
        initial={'hidden'}
        whileInView={'show'}
        viewport={{once:false, amount:.7}}
        className='lg:flex-[.7] flex-col ' >
        <span className='text-tertiary text-xl' >Welcome to</span>
        <div
         className='md:text-[40px] py-2 text-lg h-15'
        >
        <TypeAnimation
            className='font-bold text-white mb-3'
            sequence={[
                '', // Types 'One'
                1000, // Waits 1s
                'Bienestar wellness club', // Deletes 'One' and types 'Two'
                2000,
                Infinity
            ]}
            wrapper="div"
            cursor={true}
            repeat={Infinity}
            // style={{ fontSize: '2em' }}
        />
        </div> 
        <p className='text-tertiary text-justify sm:pb-10 ' >
        The genesis of BIENESTAR WELLNESS CLUB was inspired by the desire to promote wellness in our communities especially here in Kampala. We have been able to achieve this by engaging both the youths and adults in different wellness activities and meeting their different social needs. Our company team maintain a strong foundation of trust and mutual respect generated through positive relationships with clients since 2020. We are a private wellness company created and committed to nurture a healthier and more fulfilling lifestyle to the people in our society.
        </p>
      </motion.div>
      <motion.div
         variants={fadeIn('left',.3)}
         initial={'hidden'}
         whileInView={'show'}
         viewport={{once:false, amount:.7}}
        className='' >
        <img alt='' src={BPimage} className='w-80' />
      </motion.div>
    </div>
  )
}

export default memo(Banner)
