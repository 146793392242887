import React, {memo} from 'react'
import {HiHome} from "react-icons/hi"
import {BsChatSquareHeartFill} from "react-icons/bs"
import { MdMiscellaneousServices, MdWifiCalling2 } from "react-icons/md"
import {Link} from "react-scroll"
import {FaFacebook,FaInstagram, FaYoutube, FaWhatsapp} from "react-icons/fa"

function Navigation ({Menu}) {
  return (
    <div className='w-full z-51 sm:flex-col overflow-hidden flex justify-between items-center bottom-4 text-primary' >
      <div className='rounded-full py-2 gap-y-[13px] lg:items-center px-5 flex flex-col lg:flex-row justify-between text-[14px] w-[400px] mx-auto lg:backdrop-blur-3xl' >
        <Link onClick={()=>Menu(false)} offset={-100} to={"home"} smooth className="flex gap-x-1 items-center cursor-pointer" activeClass='active' >
            <HiHome/>
            <span>Home</span>
        </Link>
        <Link onClick={()=>Menu(false)} spy={true} offset={-10} to={"services"} smooth className="flex gap-x-1 items-center cursor-pointer" activeClass='active' >
            <MdMiscellaneousServices/>
            <span>Services</span>
        </Link>
        <Link onClick={()=>Menu(false)} spy={true} offset={-100} to={"values"} smooth className="flex gap-x-1 items-center  cursor-pointer" activeClass='active' >
            <BsChatSquareHeartFill/>
            <span>Values</span>
        </Link>
        <Link onClick={()=>Menu(false)} spy={true} offset={-100} to={"contact"} smooth className="flex gap-x-1 items-center cursor-pointer" activeClass='active' >
            <MdWifiCalling2/>
            <span>Contact us</span>
        </Link>
      </div>
      {/* <div className='lg:hidden absolute bottom-10 text-primary ml-5 flex gap-x-2 mt-5 text-xl' >
                <FaWhatsapp/>
                <FaInstagram/>
                <FaYoutube/>
                <FaFacebook/>
      </div> */}
    </div>
  )
}

export default memo(Navigation)
