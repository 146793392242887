
import a from "../assets/17748327_5878303.jpg"
import b from "../assets/business-hands-joined-together-teamwork.jpg"
import c from "../assets/front-view-protective-glasses-with-hard-hat-headphones.jpg"
import d from "../assets/3585385_66230.jpg"
import e from "../assets/businesspeople-meeting-plan-analysis-graph-company-finance-strat.jpg"
import f from "../assets/14881236_5539088.jpg"


import m1 from "../assets/m1.jpg";
import m2 from "../assets/m2.jpg";
import m3 from "../assets/m3.jpg";

import e1 from "../assets/e1.jpg";
import e2 from "../assets/e2.jpg";
// import e3 from "../../assets/e3.jpg"
import e4 from "../assets/e4.jpg";
import e5 from "../assets/e5.jpg";
import e6 from "../assets/e6.jpg";

import t1 from "../assets/t1.jpg";
import t2 from "../assets/t7.jpg";
import t3 from "../assets/t3.jpg";
import t4 from "../assets/t4.jpg";
import t5 from "../assets/t5.jpg";
import t6 from "../assets/t6.jpg";
import t7 from "../assets/t7.jpg";
import t8 from "../assets/t8.jpg";
import t9 from "../assets/t9.jpg";
import t10 from "../assets/t10.jpg";

import v1 from "../assets/v1.jpg";
import v2 from "../assets/v2.jpg";
import v3 from "../assets/v3.jpg";
import v4 from "../assets/v4.jpg";
import v5 from "../assets/v5.jpg";
import v6 from "../assets/v3.jpg";
import v7 from "../assets/v1.jpg";
import v8 from "../assets/v4.jpg";

import g1 from "../assets/g1.jpeg";
import g2 from "../assets/g2.jpg";
import g3 from "../assets/g5.jpg";
import g4 from "../assets/g4.jpg";
import g5 from "../assets/g5.jpg";
import g6 from "../assets/g6.jpg";

import gy1 from "../assets/gy1.jpg";
import gy2 from "../assets/gy2.jpg";
import gy3 from "../assets/gy5.jpg";
import gy4 from "../assets/gy4.jpg";
import gy5 from "../assets/gy5.jpg";


export const services=[
    {
        id:1,
        title:"Gymnastics",
        desciption:"We provide gymnastics classes which are safe and enjoyable for both children and adults. The delivery of our gymnastics classes starts at beginner stage through our fundamental sessions and go all the way through to advance and squad levels. Our fully-qualified gymnastics instructors will lead your child through each development stage, completing the progressive development of core skills. These include balance, strength, flexibility and co-ordination. It also incorporates other activities like aerobics.",
        images:[
            {
                id:1,
                src:gy1
            },
            {
                id:2,
                src:gy2
            },
            {
                id:2,
                src:gy3
            },
            {
                id:2,
                src:gy4
            },
            {
                id:3,
                src:gy5
            }
        ]
    },
    
    {
        id:2,
        title:"Photography and videography",
        desciption:"From concept to delivery, we produce visually stunning, emotionally compelling and shareable videos that inspire and entertain. We produce branded content videos that create connections with viewers, whether that is by entertaining them, showing them how a product works, or giving them a glimpse into what goes on behind the scenes of a company. We make sure to capture and preserve your beautiful memories be it at your events or celebrations. We do that by putting ourselves in the target audience’s shoes, listening for beyond what we already know about our clients, and using our clients’ unique strengths and stories.",
        images:[
            {
                id:1,
                src:v1
            },
            {
                id:2,
                src:v2
            },
            {
                id:3,
                src:v3
            },
            {
                id:3,
                src:v4
            },
            {
                id:3,
                src:v5
            },
            {
                id:3,
                src:v6
            },
            {
                id:3,
                src:v7
            },
            {
                id:3,
                src:v8
            }
        ]
    },
    
    {
        id:3,
        title:"Domestic Tourism",
        desciption:"As part of leisure time to relax and refresh the mind Bienestar has got you covered. We offer you the most amazing experiences for relaxation, recreation and pleasure by taking along on fun and exciting travels to discover beautiful sceneries around Uganda. We provide you the leisure time in beautiful places and give you an opportunity to discover new places.",
        images:[
            {
                id:1,
                src:t1
            },
            {
                id:2,
                src:t2
            },
            {
                id:3,
                src:t3
            },
            {
                id:3,
                src:t4
            },
            {
                id:3,
                src:t5
            },
            {
                id:3,
                src:t6
            },
            {
                id:3,
                src:t7
            },
            {
                id:3,
                src:t8
            }
        ]
    },
    
    {
        id:4,
        title:"Music, dance and drama",
        desciption:"Our aim is to give our future generations with a chance to develop and also showcase their talents. We encourage their creativity, self-expression and interaction between children. We help our youths understand and appreciate more their talents specifically the art of music, dance and drama by giving them platforms to show case their talents with the help instructors.",
        images:[
            {
                id:1,
                src:a
            },
            {
                id:2,
                src:f
            },
            {
                id:3,
                src:b
            }
        ]
    },
    
    {
        id:5,
        title:"Event organizer",
        desciption:"We are event planners also known as event coordinators or event specialist. We specialize in the creation of exceptional events for private and corporate clients. We design, plan, decorate and also manage every project from conception to execution. We pride ourselves on making your life easier and also alleviating any event stress and creating an incredible experience for you to cherish forever be it a school events or company events we create incredible experiences for you.",
        images:[
            {
                id:1,
                src:e1
            },
            {
                id:2,
                src:e2
            },
            {
                id:3,
                src:e4
            }
        ]
    },
    
    {
        id:6,
        title:"Art and Graphics design.",
        desciption:"How brands visually represent themselves is just as important as the product or service they’re providing. We help brands communicate their value and identities through the following graphic design services: Printing, Branding, Engraving, Embroidery, Business Card Design, Logo Design etc",
        images:[
            {
                id:1,
                src:g2
            },
            {
                id:3,
                src:g3
            },
            {
                id:3,
                src:g4
            },
            {
                id:3,
                src:g5
            },
            {
                id:3,
                src:g6
            }
        ]
    }
]