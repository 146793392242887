import React, {useState, memo} from 'react'
import {FaFacebook,FaInstagram, FaYoutube, FaWhatsapp} from "react-icons/fa"
import {motion} from "framer-motion"
import { fadeIn } from './variants'
import Navigation from './Navigation'
import { Link } from 'react-scroll'

const Contact = () => {
  
  const[Contact, setContact] = useState({
      email:'',
      message:''
  })
  
  const [Menu, setMenu] = useState(false)

  const sendEmail = (e)=>{
    e.preventDefault();
    window.location.href='mailTo:info@bienestar-wellness.org?body='+Contact.message
  }

  const handleChange=(e)=>{
      let value = e.target.value;
      setContact({message:value})
  }
  return (
    <div id="contact" className='w-full flex flex-col' >
      <div className='w-[90%] flex flex-col lg:flex-row mx-auto items-center py-10 ' >
        <motion.div
            variants={fadeIn('right',.3)}
            initial={'hidden'}
            whileInView={'show'}
            viewport={{once:false, amount:.7}}
            className='flex-1' >
            <span className='text-tertiary pb-3 border-b border-tertiary/20' >More to enquire about us?</span>
            <h2 className='text-3xl font-bold text-tertiary mt-5' >
                Contact Us here<br/>or through our social media pages
            </h2>
            <div className='text-tertiary flex gap-x-2 mt-5 text-2xl' >
                <FaWhatsapp/>
                <FaInstagram
                  onClick={()=>{
                    window.location.href = 'https://instagram.com/bienestar_wellness_club?igshid=YmMyMTA2M2Y='; 
                }}
                />
                <FaYoutube
                  onClick={()=>{
                      window.location.href='https://www.youtube.com/channel/UCESi262UdfJAqVldLingUaQ'
                  }}
                />
                <FaFacebook
                  onClick={()=>{
                      window.location.href='https://www.facebook.com/profile.php?id=100086428308787'
                  }} 
                />
            </div>
        </motion.div>
        <motion.div
            variants={fadeIn('left',.3)}
            initial={'hidden'}
            whileInView={'show'}
            viewport={{once:false, amount:.7}}
            className='w-full lg:flex-1 mt-10 lg:mt-0'>
            <form className='w-full flex flex-col ' >
                <input placeholder='your email here' className='bg-transparent py-3 border-b rounded-none placeholder-white text-white' />
                <textarea onChange={handleChange} placeholder='your message here' className='text-white placeholder-white resize-none h-24 border-b bg-transparent py-3 rounded-none' />
                
                <button onClick={sendEmail} className='border w-[150px] mt-5 hover:bg-black/30 rounded-full p-5 py-2 bg-transparent text-tertiary' >Send message</button>
            </form>
        </motion.div>
      </div>
      <div className='flex bg-white/100 w-full py-3 ' >
        <Navigation Menu={setMenu} />
      </div>
      
    <div className='flex flex-col md:flex-row items-center justify-center text-tertiary gap-x-10 ' >
        
        <div className='py-2 border-t-[1px] md:border-t-[0px] border-white/10 md:py-0' >
            <span className='text-sm' >&copy; Bienestar wellness club 2k23</span>
        </div>
    </div>
    </div>
  )
}

export default memo(Contact)
