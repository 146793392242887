import React, { memo } from 'react'
import {motion} from "framer-motion"
import { fadeIn } from "./variants"
import { GiFlyingFlag } from "react-icons/gi"
import { SiWalkman } from "react-icons/si"

const Vision = () => {
  return (
    <div className='bg-white backdrop-blur-sm w-full py-[100px]' >
        <div className='flex flex-col lg:flex-row w-[85%] lg:gap-x-10 mx-auto text-center text-primary p-5 ' >
            <motion.div
                variants={fadeIn('right',.3)}
                initial={'hidden'}
                whileInView={'show'}
                viewport={{once:false, amount:.7}}
                className='flex flex-col items-center flex-1 justify-center' >
                <GiFlyingFlag className='text-[50px] mb-5' />
                <h2 className='text-3xl font-bold hover:uppercase ' >Vision</h2>
                <p className='mt-3' >
                As a company, we have a vision to create a sustainable health and wellness services which will bring out the best possible outcomes and exceed our clients expectations in us.
                </p>
            </motion.div>
            <motion.div
                variants={fadeIn('left',.3)}
                initial={'hidden'}
                whileInView={'show'}
                viewport={{once:false, amount:.7}}
                className='flex flex-1 flex-col items-center justify-center' >
                <SiWalkman className='text-[50px] mb-5' />
                <h2 className='text-3xl font-bold mt-5 lg:mt-0  hover:uppercase ' >Mission</h2>
                <p className='mt-3' >
                Our mission is to create holistic health literacy, and develop each person as a shining example of health and wellness and to empower the people to be the wellness champion for their family and society at large.
                </p>
            </motion.div>
        </div>
    </div>
  )
}

export default memo(Vision)
