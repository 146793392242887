import React,{memo} from 'react'
import {HiDotsHorizontal} from "react-icons/hi"
import Val from "../assets/17748327_5878303.jpg"
import INtegrity from "../assets/business-hands-joined-together-teamwork.jpg"
import Safety from "../assets/front-view-protective-glasses-with-hard-hat-headphones.jpg"
import Accountability from "../assets/3585385_66230.jpg"
import transparency from "../assets/businesspeople-meeting-plan-analysis-graph-company-finance-strat.jpg"
import Listening from "../assets/14881236_5539088.jpg"
import {motion} from "framer-motion"


const Values = () => {
    const values = [
        {
            id:1,
            title:'Our values',
            description:'In service of our community, we embody all these values to create change:',
            icon:Val
        },
        {
            id:1,
            title:'Integrity',
            description:'We act with absolute integrity through values-based actions',
            icon:INtegrity
        },
        {
            id:1,
            title:'Safety',
            description:'We provide a safe, healthy, inclusionary training and learning environment to our clients',
            icon:Safety
        },
        {
            id:1,
            title:'Accountability',
            description:'We hold ourselves responsible and accountable for all our actions.',
            icon:Accountability
        },
        {
            id:1,
            title:'Transparency',
            description:'We operate and communicate with transparency',
            icon:transparency
        },
        {
            id:1,
            title:'Listening',
            description:'We treat everyone especially our clients with the utmost respect, and listen to their challenges and perspectives',
            icon:Listening
        },
    ]
  return (
    <div className='w-full text-center py-5 lg:w-[90%] mx-auto' id="values" >
      <h2 className='font-bold uppercase text-3xl text-tertiary mt-5 mb-5 ' >Our values</h2>
      <div className='grid grid-col grid-cols-1 lg:grid-cols-3 overflow-visible gap-x-5 gap-y-5 p-5' >
        {
            values.map((value)=>(
                <div key={value.id} className='text-center text-tertiary font-bold h-[250px] group overflow-hidden relative border-1' >
                    <div className='bg-black/50 group-hover:bg-black/60 group-hover:backdrop-blur-sm w-full h-full absolute z-40 transition-all duration-300' ></div>
                    <div className='w-full h-full' >
                        <img alt='' src={value.icon} className="w-full h-full object-cover cover-full " />
                    </div>
                    <div className='transition-all absolute left-0 bottom-5 duration-500 z-40 w-full text-left' >
                        <div className='w-[90%] mx-auto' >
                            <h2 className='text-left uppercase' >{value.title}</h2>
                            <p className='mt-2 h-[100px]' >{value.description}</p>
                        </div>
                    </div>
                </div>
            ))
        }
      </div>
    </div>
  )
}

export default memo(Values)
